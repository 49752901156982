<template>
  <div class="home-container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="home-title">
          <span>PINO</span>
          <span>BENÍTEZ</span>
        </h1>
        <div class="subtitle">
          <p>creativity & sensitivity</p>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="carousel">
        <div class="carousel__face"></div>
        <div class="carousel__face"></div>
        <div class="carousel__face"></div>
        <div class="carousel__face"></div>
        <div class="carousel__face"></div>
        <div class="carousel__face"></div>
        <div class="carousel__face"></div>
        <div class="carousel__face"></div>
        <div class="carousel__face"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  data() {
    return {
      // whatever: 'example',
    };
  },
  methods: {
    metodo1() {
      console.log("");
    },
  },
};
</script>
<style scoped>
.home-container {
  min-height: 100vh;
}
/*Contenedores necesarios para el carrusel*/
.wrapper {
  position: relative;
  width: 320px;
  margin: 0 auto;
  perspective: 1000px;
}

.carousel {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: rotate360 60s infinite forwards linear;
}

/*Cada cuadro del carrusel*/
.carousel__face {
  position: absolute;
  width: 300px;
  height: 250px;
  top: 20px;
  left: 10px;
  right: 10px;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.1);
  display: flex;
  margin-bottom: 20px;
}

.carousel__face:nth-child(1) {
  background-image: url("./../assets/images/Aires.jpg");
  transform: rotateY(0deg) translateZ(430px);
}
.carousel__face:nth-child(2) {
  background-image: url("./../assets/images/Architecture 1.jpg");
  transform: rotateY(40deg) translateZ(430px);
}
.carousel__face:nth-child(3) {
  background-image: url("./../assets/images/Armonia Enso.jpg");
  transform: rotateY(80deg) translateZ(430px);
}
.carousel__face:nth-child(4) {
  background-image: url("./../assets/images/Asian Face.jpg");
  transform: rotateY(120deg) translateZ(430px);
}
.carousel__face:nth-child(5) {
  background-image: url("./../assets/images/El descanso (Aires).png");
  transform: rotateY(160deg) translateZ(430px);
}
.carousel__face:nth-child(6) {
  background-image: url("./../assets/images/Geisha en el jardín.jpg");
  transform: rotateY(200deg) translateZ(430px);
}
.carousel__face:nth-child(7) {
  background-image: url("./../assets/images/Jessica,s mask.jpg");
  transform: rotateY(240deg) translateZ(430px);
}
.carousel__face:nth-child(8) {
  background-image: url("./../assets/images/la dama del armino.jpg");
  transform: rotateY(280deg) translateZ(430px);
}
.carousel__face:nth-child(9) {
  background-image: url("./../assets/images/Niña pelirroja .jpg");
  transform: rotateY(320deg) translateZ(430px);
}

@keyframes rotate360 {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}

/* Estilo del título */
.home-title span {
  position: relative;
  overflow: hidden;
  display: block;
  line-height: 1.2;
  letter-spacing: 10px;
  font-size: 70px;
  font-family: "Maitree", serif;
}

.home-title span::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  animation: a-ltr-after 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
  transform: translateX(-101%);
}

.home-title span::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  animation: a-ltr-before 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
  transform: translateX(0);
}

.home-title span:nth-of-type(1)::before,
.home-title span:nth-of-type(1)::after {
  animation-delay: 0s;
}

.home-title span:nth-of-type(2)::before,
.home-title span:nth-of-type(2)::after {
  animation-delay: 0.5s;
}

@keyframes a-ltr-after {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(101%);
  }
}

@keyframes a-ltr-before {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}

/* Estilo del subtítulo */
.subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle p {
  visibility: hidden;
  font-weight: 700;
  text-align: center;
  font-family: Hack, sans-serif;
  /* text-transform: uppercase; */
  background: linear-gradient(90deg, #fff, #000, #fff);
  letter-spacing: 5px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat;
  background-size: 80%;
  animation: shine 5s linear infinite 1.5s;
  position: relative;
}

@keyframes shine {
  0% {
    background-position-x: -500%;
  }
  100% {
    background-position-x: 500%;
    visibility: visible;
  }
}

/* Media query para cambiar el carrusel circular por otro en responsive */
@media (max-width: 990px) {
  .carousel {
    width: 100%;
    animation: none;
  }

  @keyframes display {
    0% {
      transform: translateX(200px);
      opacity: 0;
    }
    10% {
      transform: translateX(0);
      opacity: 1;
    }
    20% {
      transform: translateX(0);
      opacity: 1;
    }
    30% {
      transform: translateX(-200px);
      opacity: 0;
    }
    100% {
      transform: translateX(-200px);
      opacity: 0;
    }
  }

  /* .carousel {
    position: relative;
    width: 100vw;
    height: 300px;
    margin-top: 15vh;
  } */

  .carousel__face {
    /* position: absolute; */
    top: 0;
    /* left: calc(50% - 100px); */
    opacity: 0;
    height: 50vh;
    /* width: 100%; */
    animation: display 10s infinite;
    object-fit: cover;
  }

  .carousel__face:nth-child(1) {
    animation-delay: 2s;
  }
  .carousel__face:nth-child(2) {
    animation-delay: 4s;
  }
  .carousel__face:nth-child(3) {
    animation-delay: 6s;
  }
  .carousel__face:nth-child(4) {
    animation-delay: 8s;
  }
  .carousel__face:nth-child(5) {
    animation-delay: 10s;
  }
  .carousel__face:nth-child(6) {
    animation-delay: 12s;
  }
  .carousel__face:nth-child(7) {
    animation-delay: 14s;
  }
  .carousel__face:nth-child(8) {
    animation-delay: 16s;
  }
  .carousel__face:nth-child(9) {
    animation-delay: 18s;
  }
}

/* @media (max-height: 700px) {
  .carousel__face {
    height: 150px;
  }
} */
</style>