<template>
  <div>
    <b-navbar class="nav-custom" toggleable="md" type="light" variant="transparent">
      <b-navbar-brand href="/"
        ><img src="./../assets/images/logo-pino_2.jpg" alt="Logo" class="logo"
      /></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"> </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/obras">Obras</b-nav-item>
          <b-nav-item href="/sobre-mi">Sobre mí</b-nav-item>
          <b-nav-item href="/contacto">Contacto</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: "Nav"
};
</script>

<style>
.navbar {
  padding: 0.5rem 1rem !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.logo {
  height: 60px;
  width: 60px;
}

.nav-link {
  color: black !important;
  font-size: 18px !important;
}

.nav-item a:hover {
  text-decoration: underline;
}
</style>
