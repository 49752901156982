<template>
  <div id="app">
    <Nav />
    <router-view />
    <div class="preFooter-div"></div>
    <Footer />
  </div>
</template>

<script>
import Nav from "./layout/Nav.vue";
import Footer from "./layout/Footer.vue";

export default {
  name: "App",
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Nav,
    // eslint-disable-next-line vue/no-reserved-component-names
    Footer,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Belleza&display=swap");

#app {
  font-family: "Belleza", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

#app h1 {
  margin-bottom: 20px;
}

.preFooter-div {
  padding-bottom: 1.5rem;
}
/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983; */
</style>
