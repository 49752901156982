 <template>
  <button :is="type" :href="href" class="button" @click="$emit('click')">
    <slot />
  </button>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Btn",
  props: {
    href: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
  },
  computed: {
    type() {
      if (this.href) {
        return "a";
      } else {
        return "button";
      }
    },
  },
};
</script>

<style scoped>
.button {
  display: inline-block;
  margin: 0.5em 0;
  padding: 0.5em;
  background: #fff;
  border: 2px solid #967b35;
  border-radius: 3px;
  color: #967b35;
  font-family: "Quicksand", sans-serif;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1;
  text-decoration: none;
  /* text-transform: uppercase; */
  cursor: pointer;
  transition: 0.3s;
}

.button:hover {
  background: #967b35;
  color: #fff;
}
</style>