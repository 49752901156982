import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Paintings from '../views/Paintings.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home, // mediante el import de arriba se reconoce al componente, pero es más directo como abajo
  },
  {
    path: "/obras",
    name: "Paintings",
    // component: () => import(/* webpackChunkName: "about" */ './src/views/Paintings.vue')
    component: Paintings,
  },
  {
    path: "/obras/:painting_id",
    name: "Painting-Details",
    component: () =>
      import(/* webpackChunkName: "about" */ "./../views/Painting-Details.vue"),
  },
  {
    path: "/sobre-mi",
    name: "About-me",
    component: () =>
      import(/* webpackChunkName: "about" */ "./../views/About-me.vue"),
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: () =>
      import(/* webpackChunkName: "about" */ "./../views/Contact.vue"),
  },
  {
    path: "/validar-usuario",
    name: "CheckUser",
    component: () =>
      import(/* webpackChunkName: "about" */ "./../views/CheckUser.vue"),
  },
  {
    path: "/editar/obras",
    name: "EditPaintingList",
    component: () =>
      import(/* webpackChunkName: "about" */ "./../views/EditPaintingsList.vue"),
  },
  {
    path: "/editar/obras/:painting_id",
    name: "EditPainting",
    component: () =>
      import(/* webpackChunkName: "about" */ "./../views/EditPainting.vue"),
  },
  {
    path: "/editar/sobre-mi",
    name: "EditPainting",
    component: () =>
      import(/* webpackChunkName: "about" */ "./../views/EditAboutMe.vue"),
  },
  {
    path: "/panel-de-edicion",
    name: "EditPanel",
    component: () =>
      import(/* webpackChunkName: "about" */ "./../views/EditPanel.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
