import axios from "axios";

export default class PaintingsService {
  constructor() {
    this.apiHandler = axios.create({
      // baseURL: 'http://localhost:3000/api',
      baseURL: process.env.VUE_APP_PAINTINGS_API,
      withCredentials: true,
    });
  }

  getPaintings = () => this.apiHandler.get("/paintings/getAllPaintings");
  getPainting = (paintingId) =>
    this.apiHandler.get(`/paintings/getOnePainting/${paintingId}`);
  updatePainting = (paintingId, paintingInfo) =>
    this.apiHandler.put(`/paintings/editPainting/${paintingId}`, paintingInfo);
  deletePainting = (paintingId) =>
    this.apiHandler.delete(`/paintings/deletePainting/${paintingId}`);
}
