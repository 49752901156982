<template>
  <div class="footer">
    <p>
      &copy; {{ new Date().getFullYear() }} Copyright: Adrián Monzón | <a href="/validar-usuario">Administración</a>
    </p>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: "Footer",
};
</script>
<style scoped>
.footer {
  background-color: black;
  color: #c7c3ba;
  width: 100%;
  height: 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-top: auto;
  animation: showFooter 0s 2s forwards;
  visibility: hidden;
}

a {
  text-decoration: none;
  color: #c7c3ba;
}

a:hover {
  color: #fff;
}

@keyframes showFooter {
  to {
    visibility: visible;
  }
}
</style>