<template>
  <div class="list-container">
    <h1 class="title">GALERÍA</h1>
    <div class="container">
      <div class="row" v-if="paintingsShowed">
        <div
          class="col-lg-4 col-md-6"
          v-for="(painting, index) in paintings"
          :key="index"
        >
          <div class="card text-center">
            <a :href="'/obras/' + painting._id">
              <img
                :src="painting.image"
                alt="Imagen del cuadro"
                class="painting-img"
              />
            </a>
            <div class="card-body">
              <h4 class="card-title">{{ painting.title }}</h4>
              <Btn :href="'/obras/' + painting._id">Detalles</Btn>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center" v-else>Cargando obras...</div>
    </div>
  </div>
</template>
<script>
import PaintingService from "./../../src/services/paintings.service";
import Btn from "./../components/atoms/Button.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Paintings",
  components: {
    Btn
  },
  data() {
    return {
      paintingService: new PaintingService(),
      paintings: [],
      paintingsShowed: false,
    };
  },
  mounted() {
    this.getAllPaintings();
  },
  methods: {
    getAllPaintings() {
      this.paintingService
        .getPaintings()
        .then((response) => {
          this.paintingsShowed = true;
          this.paintings = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style scoped>
.list-container {
  min-height: 100vh;
}
.card {
  margin-bottom: 20px;
  border: none;
}
.painting-img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  border: 10px inset#EAC053;
}
</style>